import { useCallback, useEffect, useState } from 'react';
import { useFeatureFlags } from '../third-parties/launchdarkly';
import { DashboardMenuItemWithBeta } from '../components/dashboard/DashboardLayout';
import { getDashboardMenuItems } from '../components/dashboard/utils';
import useEntitlements, { EntitlementName } from './useEntitlements';
import { useWebsession } from './useWebsession';
import { isExclusivelyGroupReporter } from '../utils/userRoles';

const dashboardItemIdToEntitlementKey: { [key: number]: string } = {
  4310: 'entManageOrganisation', // Manage organisation
  4311: 'entManageUsers', // Manage users
  4312: 'entManageTeam', // Manage groups
  4313: 'entManageOrganisationLog', // Action log
  4314: 'entManageRoles', // Role distribution
  4321: 'entReportIndividual', // User report
  4326: 'entReportTeam', // Group report
  4322: 'entReportCourse', // Course report
  4323: 'entReportActivities', // Detailed user report
  4324: 'entReportMilestone', // Milestone report
  4325: 'entReportTeamMembers', // Group member overview
  4331: 'entConnectionMethodLti11', // LTI settings
  4337: 'entConnectionMethodLti13', // LTI 1.3 settings
  4332: 'entConnectionMethodRs', // Remote SCORM settings , entConnectionMethodRsMlscorm entitlement is directly related
  4334: 'entConnectionSelectionCourses', // Course selection
  4333: 'entConnectionSelectionGoodscan', // GoodScan selection
  4336: 'entConnectionSelectionSingleActivities', // Single Activities
  4354: 'entConnectionSelectionAssessments', // Assessments Selection
  4382: 'entConnectionSelectionLessons', // Lesson Selection
  4341: 'entRecommendOrganisation', // Recommend courses to everyone
  4342: 'entRecommendTeam', // Recommend courses to groups
  4351: 'entResourcesManualsDocuments', // Manuals and documents
  4352: 'entResourcesCoursesImagesLeafletsWorkouts', // Course images, leaflets and WorkOuts
  4353: 'entResourcesPromoStudio', // Promo Studio
  4360: 'entReportAssessment', // Assessment insights
};

export default function useDashboardMenuAccess() {
  const {
    dashLti13ConfigPage: lti13FeatureFlag,
    smeAdminOnboarding: smeAdminOnboardingFeatureFlag,
    learningLabz: learningLabzFeatureFlag,
    lessons: lessonsFeatureFlag,
    oScanReportPage: oScanReportPageFeatureFlag,
  } = useFeatureFlags();

  const entitlements = useEntitlements();
  const [menuTree] = useWebsession('MenuTree');
  const [menuItems, setMenuItems] = useState<DashboardMenuItemWithBeta[]>([]);

  const filterMenuItemsByEntitlements = useCallback(
    (menuItems: DashboardMenuItemWithBeta[]) => {
      const itemsWithFilteredSubItems = menuItems.map((item) => {
        if (item.SubItems?.length) {
          const filteredSubItems = item.SubItems.filter((subItem) => {
            const subEntitlementKey =
              dashboardItemIdToEntitlementKey[subItem.Id];
            return entitlements[subEntitlementKey as EntitlementName] ?? true;
          });
          return {
            ...item,
            SubItems: filteredSubItems,
          };
        }
        return item;
      });

      return itemsWithFilteredSubItems.filter((item) => {
        const entitlementKey = dashboardItemIdToEntitlementKey[item.Id];
        // the item should be invisible if all subitems have been removed
        if (item.SubItems && item.SubItems.length === 0) return false;

        return entitlements[entitlementKey as EntitlementName] ?? true;
      });
    },
    [entitlements],
  );

  useEffect(() => {
    if (!menuTree) return;
    let dashboardMenuItems: DashboardMenuItemWithBeta[] =
      getDashboardMenuItems(menuTree);

    const lessonSelectionSubItemId = 4382;
    const lti13ItemId = 4337;
    const launchItemId = 4370;
    const aiItemId = 4380;
    const oScanItemId = 4383;

    dashboardMenuItems = filterMenuItemsByEntitlements(dashboardMenuItems);
    const connectMenuItem = dashboardMenuItems.find((item) => item.Id === 4330);
    const manageOrganisationMenuItem = dashboardMenuItems.find(
      (item) => item.Id === 4310,
    );

    const isLessonSelectionVisible = lessonsFeatureFlag;

    if (connectMenuItem) {
      connectMenuItem.SubItems = connectMenuItem.SubItems?.filter((item) => {
        // Remove lesson selection subitem if feature flag is disabled
        if (item.Id === lessonSelectionSubItemId && !isLessonSelectionVisible)
          return false;
        if (item.Id === lti13ItemId && !lti13FeatureFlag) return false;
        return true;
      });
    }

    if (manageOrganisationMenuItem?.SubItems && !oScanReportPageFeatureFlag) {
      manageOrganisationMenuItem.SubItems =
        manageOrganisationMenuItem.SubItems.filter(
          (item) => item.Id !== oScanItemId,
        );
    }

    dashboardMenuItems = dashboardMenuItems.filter((item) => {
      if (item.Id === launchItemId && !smeAdminOnboardingFeatureFlag)
        return false;
      if (item.Id === aiItemId && !learningLabzFeatureFlag) return false;
      return true;
    });

    if (isExclusivelyGroupReporter())
      dashboardMenuItems = dashboardMenuItems.filter(
        (item) => item.Id !== 4305,
      );

    setMenuItems(dashboardMenuItems);
  }, [
    lti13FeatureFlag,
    smeAdminOnboardingFeatureFlag,
    learningLabzFeatureFlag,
    lessonsFeatureFlag,
    oScanReportPageFeatureFlag,
    entitlements,
    filterMenuItemsByEntitlements,
    menuTree,
  ]);

  return menuItems;
}
