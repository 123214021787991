import tw, { styled } from 'twin.macro';
import { useEffect } from 'react';
import useTranslation from '../../hooks/useTranslation';
import { GlobalStyles } from './GlobalStyles';
import type { PropsWithChildren } from 'react';
import { isCookieConsentNeeded } from '../../utils/sessionUtils';

type Props = {
  pageId: string;
  layout?: 'centered' | 'plain' | 'plain centered';
  background?: 'grey';
};

const PageContainer = styled.div(({ layout, background }: Props) => {
  const styles = [];

  if (layout?.includes('centered')) {
    styles.push(tw`grid place-content-center h-screen`);
  }

  if (background === 'grey') {
    styles.push(tw`bg-grey-5`);
  }

  if (isCookieConsentNeeded()) {
    styles.push(tw`mb-[55vh] md:mb-[35vh] lg:mb-[30vh]`);
  }

  return styles;
});

const Page = (props: PropsWithChildren<Props>) => {
  const t = useTranslation(props.pageId);

  useEffect(() => {
    const pageTitle = t('txt_page_title');
    if (pageTitle && pageTitle !== '') {
      document.title = pageTitle;
    }
  }, [t]);

  return (
    <PageContainer {...props}>
      <GlobalStyles />
      {props.children}
    </PageContainer>
  );
};

export default Page;
