import { get_language } from '../vanilla-js/common';
import { LDFlagSet, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { launchDarklyClientId } from '../config';

import type { LoggedInUser } from '../vanilla-js/websession';
import { useEffect, useState } from 'react';

export { useFlags as useFeatureFlags } from 'launchdarkly-react-client-sdk';

export const initializeLaunchDarkly = async () =>
  asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
  });

export function useIdentifyLaunchDarkly(user: LoggedInUser, ldClient: any) {
  const [launchDarklyIdentify, setLaunchDarklyIdentify] =
    useState<Promise<LDFlagSet>>();

  useEffect(() => {
    if (ldClient && user && user.Id > 0) {
      const isGoodHabitzAccount = user.CompanyTypeId === 1;

      const context = {
        kind: 'multi',
        company: {
          key: user.ExternalCompanyReference,
          name: user.CompanyName,
          country: user.Country ?? '',
        },
        language: {
          key: get_language(),
        },
        loginOrigin: {
          key: user.LoginOrigin ?? '',
        },

        // Will conditionally add the goohabitz employee if the user is a goodhabitz employee
        goodhabitzEmployee: isGoodHabitzAccount
          ? {
              key: user.ExternalPersonReference,
              firstName: user.FirstName,
              lastName: user.LastName,
            }
          : undefined,
      };

      setLaunchDarklyIdentify(
        new Promise((resolve, reject) => {
          ldClient.identify(
            context,
            null,
            (error: null | Error, flags: LDFlagSet) => {
              if (error) {
                reject(error);
              }

              resolve(flags);
            },
          );
        }),
      );
    }
  }, [user, ldClient]);

  return launchDarklyIdentify;
}
