import { getGlobalVariableByEnv } from '../vanilla-js/common';

export const goodScanListEndpoint = '/api/goodscanlist';
export const ghccStartActivityEndpoint = '/api/ghccStartActivity';
export const languageEndpoint = (lanugageCode: string) =>
  `/lang/${lanugageCode}.json`;
export const crowdInLanguageEndpoint = (lanugageCode: string) =>
  `/crowdin-lang/${lanugageCode}.json`;
export const lti13SettingsEndpoint = '/api/lti13settings';
export const websessionEndpoint = '/api/websession2';
export const websessionWithIdEndpoint = '/api/websession2/0';
export const tokenEndpoint = '/api/token';
export const courseListEndpoint = (page: number) => `/api/courseList/${page}`;
export const adminCourseEndpoint = '/api/admincourse';
export const accountUpdateEndpoint = '/api/personal';
export const passwordUpdateEndpoint = '/api/password';
export const downloadUserDataEndpoint = '/api/DownloadUserdata';
export const activateVoucherEndpoint = '/api/voucher';
export const mailingListManagementEndpoint = '/api/MailingListManagement';
export const courseDownloadEndpoint = '/api/CourseDownload';
export const contactAndSupportEndpoint = '/api/formsubmit';
export const relatedLibraryCoursesEndpoint = (language: string) =>
  `${getGlobalVariableByEnv(
    'BFF_CATALOG_ENDPOINT',
  )}/api/library/related/${language}`;
export const libraryHierarchyEndpoint = `${getGlobalVariableByEnv(
  'BFF_CATALOG_ENDPOINT',
)}/api/library/hierarchy`;
export const leafletsExportEndpoint = `${getGlobalVariableByEnv(
  'BFF_CATALOG_ENDPOINT',
)}/api/export-leaflets`;
export const learningBitesEndpoint = (language: string) =>
  `${getGlobalVariableByEnv(
    'BFF_CATALOG_ENDPOINT',
  )}/api/learning-bites/${language}`;
export const lessonsEndpoint = (language: string) =>
  `${getGlobalVariableByEnv('BFF_CATALOG_ENDPOINT')}/api/lessons/${language}`;
export const profileEndpoint = '/api/profile';
export const supportConsentEndpoint = (personId: number) =>
  `/api/login-as/consent/${personId}`;
export const supportLogsEndpoint = (personId: number) =>
  `/api/login-as/logs/${personId}`;
export const legalDocumentDownloadEndpoint = '/api/LegalDocumentDownload';
export const playerResultsEndpoint = '/api/ghccGetPlayerResult';
export const ghccSsoEndpoint = '/api/ghccSso';
export const otpEndpoint = '/api/otp/code';
export const aiSearchAlgoliaEndpoint = (text: string, languageRegion: string) =>
  `${getGlobalVariableByEnv(
    'CONTENT_CATALOG_BFF_ENDPOINT',
  )}/content/ai-search-algolia?text=${text}&language=${languageRegion}`;
