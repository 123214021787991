import React from 'react';
import tw, { styled, type TwStyle } from 'twin.macro';

type Type = 'solid' | 'light' | 'outline';
type Theme = 'primary' | 'error' | 'warning' | 'success' | 'info' | 'neutral';
type Size = 'default' | 'compact';

interface BadgeProps {
  type?: Type;
  theme?: Theme;
  size?: Size;
  children: React.ReactNode;
}

const colorClasses: Record<Type, Record<Theme, TwStyle>> = {
  solid: {
    primary: tw`bg-primary-default text-primary-text-default`,
    error: tw`bg-error text-primary-text-default`,
    warning: tw`bg-warning text-grey-100`,
    success: tw`bg-success text-primary-text-default`,
    info: tw`bg-info text-primary-text-default`,
    neutral: tw`bg-neutral-dark text-grey-100`,
  },
  light: {
    primary: tw`bg-blue-10 text-primary-default`,
    error: tw`bg-error-light text-error`,
    warning: tw`bg-warning-light text-warning-dark`,
    success: tw`bg-success-light text-success`,
    info: tw`bg-info-light text-info`,
    neutral: tw`bg-neutral-medium text-grey-100`,
  },
  outline: {
    primary: tw`text-primary-text-default border border-primary-default`,
    error: tw`text-error border border-error`,
    warning: tw`text-warning-dark border border-warning-dark`,
    success: tw`text-success border border-success`,
    info: tw`text-info border border-info`,
    neutral: tw`text-grey-100 border border-grey-100`,
  },
};

const sizeClasses: Record<Size, TwStyle> = {
  default: tw`py-2 px-4`,
  compact: tw`py-1 px-3`,
};

const StyledBadge = styled.span<BadgeProps>(
  ({ type = 'solid', theme = 'primary', size = 'default' }: BadgeProps) => [
    tw`rounded-small text-sm leading-normal tracking-[0.5px]`,
    colorClasses[type][theme],
    sizeClasses[size],
  ],
);

export const Badge: React.FC<BadgeProps> = ({
  type = 'solid',
  theme = 'primary',
  size = 'compact',
  children,
}) => {
  return (
    <StyledBadge type={type} theme={theme} size={size}>
      {children}
    </StyledBadge>
  );
};

export default Badge;
