import { useEffect, useState, forwardRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardMenuItem, edit_url } from '../../vanilla-js/client';
import tw, { css, styled } from 'twin.macro';
import { getActivePageIndex } from './utils';
import useTranslation from '../../hooks/useTranslation';
import {
  Button,
  Badge,
  BookIcon,
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  AddressBookIcon,
  GaugeHighIcon,
  ChartColumnIcon,
  PlugIcon,
  GraduationCapIcon,
  LightbulbIcon,
  RocketLaunchIcon,
  SparklesIcon,
} from '../../common-components';

interface DashboardMenuItemWithBeta extends DashboardMenuItem {
  SubItems?: Array<
    DashboardMenuItem & {
      hasNewFlag?: boolean;
      hasBetaFlag?: boolean;
    }
  >;
}

const StyledMenuItemsContainer = styled.div(
  ({ isMenuOpen }: { isMenuOpen: boolean }) => [
    tw`sm:(w-10) flex-shrink-0 z-50 overflow-x-hidden bg-grey-0 transition-all`,
    isMenuOpen ? tw`w-10` : tw`w-0`,
  ],
);

const StyledMenuItemButton = styled.button(
  ({ $clicked, $color }: { $clicked: boolean; $color: string }) => [
    tw`flex no-underline cursor-pointer flex-wrap content-center justify-center w-10 h-10 hover:(text-grey-0 bg-grey-20)`,
    css`
      & > svg {
        width: 1.7rem;
        height: auto;
      }
    `,
    $clicked && [
      tw`text-grey-0`,
      css`
        background-color: ${$color};
        &:hover {
          background-color: ${$color};
        }
      `,
    ],
  ],
);

const StyledSliderContainer = styled.div(
  ({
    isSliderOpen,
    isMenuOpen,
  }: {
    isSliderOpen: boolean;
    isMenuOpen: boolean;
  }) => [
    tw`bg-grey-0 px-6 pt-4 absolute h-full left-13 sm:left-10 transition-all overflow-x-hidden`,
    isSliderOpen &&
      ((!isMenuOpen && tw`w-0 opacity-0 sm:(w-[24rem] opacity-100)`) ||
        tw`w-[16rem] -ml-8 sm:(w-[24rem] ml-0)`),
    !isSliderOpen && tw`w-0 opacity-0 sm:(p-0)`,
  ],
);

const StyledSliderCloseButton = styled(Button)(() => [
  tw`h-10 justify-end bg-transparent min-w-full text-grey-50 text-4xl hover:(bg-transparent text-grey-100 cursor-pointer) active:(text-grey-50 bg-transparent)`,
]);

const StyledSliderItem = styled.a(({ color }) => [
  tw`block overflow-x-hidden no-underline p-4 mr-3 hover:(cursor-pointer text-grey-0)`,
  css`
    &:hover {
      background-color: ${color};
    }
  `,
]);

const StyledPullButton = styled(StyledSliderCloseButton)(() => [
  tw`h-9 self-center z-40 min-w-max bg-grey-0 text-blue-100 text-5xl shadow-s rounded-full -ml-6 mr-3 active:(text-blue-100) sm:(hidden) transition-all`,
]);

type DashboardMenuProps = {
  menuItems: DashboardMenuItemWithBeta[];
};

const menuItemIconMap: Record<DashboardMenuItem['Id'], JSX.Element> = {
  4305: <GaugeHighIcon />, // Dashboard home
  4310: <AddressBookIcon />, // Organisation
  4320: <ChartColumnIcon />, // Reports
  4330: <PlugIcon />, // Connect
  4340: <GraduationCapIcon />, // Recommend
  4350: <BookIcon />, // Resources
  4380: <SparklesIcon />, // AI
  4360: <LightbulbIcon />, //Assessments
  4370: <RocketLaunchIcon />, // Launch
};

export const DashboardMenu = forwardRef<HTMLDivElement, DashboardMenuProps>(
  ({ menuItems }, dashboardRef) => {
    const [active, setActive] = useState<{ [key: number]: boolean }>({});
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] =
      useState<DashboardMenuItemWithBeta>();
    const t = useTranslation();
    let location = useLocation();

    useEffect(() => {
      if (!isSliderOpen) {
        const activeItemIndex = getActivePageIndex(menuItems);
        setActive({ [activeItemIndex]: true });
      }
    }, [isSliderOpen, menuItems]);

    useEffect(() => {
      setIsSliderOpen(false);
    }, [location]);

    const clickHandler = (key: number) => () => {
      setActive({ [key]: true });
      setSelectedMenuItem(menuItems[key]);
      if (menuItems[key].SubItems) {
        setIsSliderOpen(true);
      }
    };

    const sliderClickHandler = () => {
      setIsSliderOpen(!isSliderOpen);
    };

    const pullButtonClickHandler = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const shouldShowHelpCenter =
      !!t('menu_help_center', { silent: true }) &&
      !t('menu_help_center', { silent: true }).startsWith('[') &&
      t('menu_help_center', { silent: true }) !== '...';

    return (
      <nav
        tw="flex absolute top-0 h-[calc(100% - 350px)] transition-transform z-50"
        data-testid="DashboardMenu"
      >
        <StyledMenuItemsContainer isMenuOpen={isMenuOpen} ref={dashboardRef}>
          {menuItems?.map((dashboardMenuItem, i) => (
            <StyledMenuItemButton
              as={!dashboardMenuItem.SubItems ? 'a' : undefined}
              href={
                !dashboardMenuItem.SubItems
                  ? edit_url(dashboardMenuItem.Url)
                  : undefined
              }
              onClick={clickHandler(i)}
              $clicked={active[i]}
              $color={dashboardMenuItem.Color}
              key={dashboardMenuItem.Id}
            >
              {menuItemIconMap[dashboardMenuItem.Id]}
            </StyledMenuItemButton>
          ))}
        </StyledMenuItemsContainer>
        <StyledPullButton onClick={pullButtonClickHandler}>
          {isMenuOpen && <ChevronLeftIcon tw="ml-5" />}
          {!isMenuOpen && <ChevronRightIcon tw="ml-5" />}
        </StyledPullButton>
        <StyledSliderContainer
          isSliderOpen={isSliderOpen}
          isMenuOpen={isMenuOpen}
        >
          <StyledSliderCloseButton onClick={sliderClickHandler}>
            <ArrowLeftIcon />
          </StyledSliderCloseButton>
          {selectedMenuItem && (
            <StyledSliderItem
              color={selectedMenuItem.Color}
              href={edit_url(selectedMenuItem.Url)}
              tw="text-[18px] font-bold"
            >
              {selectedMenuItem.Name}
            </StyledSliderItem>
          )}
          {selectedMenuItem &&
            selectedMenuItem.SubItems?.map((dashboardSubMenuItem) => (
              <Fragment key={dashboardSubMenuItem.Id}>
                <StyledSliderItem
                  color={dashboardSubMenuItem.Color}
                  key={dashboardSubMenuItem.Id}
                  href={edit_url(dashboardSubMenuItem.Url)}
                >
                  {dashboardSubMenuItem.Name}
                  {dashboardSubMenuItem.hasNewFlag && (
                    <div tw="inline-block ml-5">
                      <Badge theme="success" type="light">
                        {t('new')}
                      </Badge>
                    </div>
                  )}
                  {dashboardSubMenuItem.hasBetaFlag && (
                    <div tw="inline-block ml-5">
                      <Badge theme="warning" type="light">
                        {t('beta')}
                      </Badge>
                    </div>
                  )}
                </StyledSliderItem>
                {dashboardSubMenuItem.Id === 4353 && shouldShowHelpCenter && (
                  <StyledSliderItem
                    color={dashboardSubMenuItem.Color}
                    key={dashboardSubMenuItem.Id + 1}
                    href={t('menu_help_center_link')}
                  >
                    {t('menu_help_center')}
                  </StyledSliderItem>
                )}
              </Fragment>
            ))}
        </StyledSliderContainer>
      </nav>
    );
  },
);
