import tw, { styled } from 'twin.macro';

export const NewLookBannerContainer = styled.div(() => [tw`w-full p-5 pb-0`]);

export const NewLookBannerBox = styled.div(() => [
  tw`max-w-7xl mx-auto no-underline`,
  tw`flex flex-col items-center justify-between gap-x-4 gap-y-5 px-4 py-5`,
  tw`sm:flex-row sm:gap-6 sm:px-6 md:px-8 sm:py-5`,
  tw`bg-blue-100 rounded-medium text-grey-0`,
]);

export const NewLookBannerContent = styled.div(() => [
  tw`flex gap-x-4 sm:items-center`,
]);

export const NewLookBannerCTA = styled.a(() => [
  tw`flex-shrink-0 bg-[#fff] hover:bg-blue-10 transition-colors text-blue-100 px-5 py-4 rounded-small no-underline`,
]);
