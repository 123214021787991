import { Paragraph, SolidSparklesIcon } from '../../common-components';
import useTranslation from '../../hooks/useTranslation';
import {
  NewLookBannerContainer,
  NewLookBannerBox,
  NewLookBannerContent,
  NewLookBannerCTA,
} from './NewLookBannerStyles';

export const NewLookBanner = ({
  href,
  message,
}: {
  href: string;
  message: string;
}) => {
  const t = useTranslation();

  return (
    <NewLookBannerContainer>
      <NewLookBannerBox>
        <NewLookBannerContent>
          <SolidSparklesIcon style={{ flexShrink: 0, fontSize: 24 }} />
          <Paragraph>{message}</Paragraph>
        </NewLookBannerContent>

        {href && (
          <NewLookBannerCTA href={href} target="_blank">
            {t('txt_new_look_banner_cta')}
          </NewLookBannerCTA>
        )}
      </NewLookBannerBox>
    </NewLookBannerContainer>
  );
};
