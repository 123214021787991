export const version = process.env.REACT_APP_VERSION ?? 'snapshot';

const hostname = window.location.hostname;
const hostnameRegex = /website-(.+).goodhabitz.com/;
const hostnameMatch = hostnameRegex.exec(hostname)?.[1];
export const environment =
  hostnameMatch ?? (hostname === 'my.goodhabitz.com' ? 'production' : 'none');

function featureEnabled(name: string) {
  return (
    // @ts-ignore
    window[`${name}_ENABLED`] === 'true' ||
    process.env[`REACT_APP_${name}_ENABLED`] === 'true'
  );
}

export const launchDarklyClientId =
  window.LAUNCHDARKLY_CLIENT_ID ?? process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;
export const firstVisitModalEnabled = featureEnabled('FIRST_VISIT_MODAL');
